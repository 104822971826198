import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IBanner } from '../../interfaces/banner';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntensiveTimerService {
  timerHeight$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

  constructor(private http: HttpClient) {}

  getBanners() {
    return this.http.get<IBanner[]>('/api/profile/v1/info-messages');
  }
}
