<div *ngIf="isShowBanner" [style.bottom.px]="bottomHeight + 16" class="cookie-banner">
  <svg fill="none" height="24" viewBox="0 0 24 24" width="48" xmlns="http://www.w3.org/2000/svg">
    <path clip-rule="evenodd" d="M3.51472 16.2427C-6.99538e-07 12.728 -1.17988e-06 7.02948 3.51472 3.51476C7.02944 4.26926e-05 12.7279 4.22123e-05 16.2426 3.51476C17.2586 4.53076 17.9809 5.72924 18.4096 7.0047C15.2727 5.95059 11.6703 6.6729 9.17157 9.17162C6.67285 11.6703 5.95055 15.2728 7.00466 18.4096C5.7292 17.981 4.53072 17.2587 3.51472 16.2427Z" fill="#933FA1" fill-rule="evenodd"/>
    <path clip-rule="evenodd" d="M9.17158 9.17162C11.9052 6.43795 16.3374 6.43795 19.0711 9.17162C21.8047 11.9053 21.8047 16.3374 19.0711 19.0711C16.3374 21.8048 11.9052 21.8048 9.17158 19.0711C6.43791 16.3374 6.43791 11.9053 9.17158 9.17162ZM7.75736 20.4853C4.24264 16.9706 4.24264 11.2721 7.75736 7.75741C11.2721 4.24269 16.9706 4.24269 20.4853 7.75741C24 11.2721 24 16.9706 20.4853 20.4853C16.9706 24 11.2721 24 7.75736 20.4853Z" fill="#FCEDFF" fill-rule="evenodd"/>
  </svg>
  <div class="cookie-banner__text">
    Наш сайт использует файлы cookie, чтобы улучшить работу сайта, повысить его эффективность и удобство.
    Продолжая использовать сайт marketguru.io, вы соглашаетесь на использование файлов cookie.
  </div>
  <button (click)="onClose()">Согласен</button>
</div>
