import { afterRender, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MgThemeService } from '@marketguru/core';
import { MgDropdownHostModule } from '@marketguru/ui-kit-components/components/dropdown-host';
import { MgHintsHostModule } from '@marketguru/ui-kit-components/components/hints-host';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AppLayoutComponent } from './shared/components/app-layout/app-layout.component';
import { MetaService } from './shared/services/meta.service';
import { loadScript } from './shared/utils/load-script';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, AppLayoutComponent, MgHintsHostModule, MgDropdownHostModule]
})
export class AppComponent implements OnInit {
  readonly #themeService = inject(MgThemeService);
  readonly #router = inject(Router);
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #metaService = inject(MetaService);

  constructor() {
    afterRender(() =>
      loadScript('get-review', 'https://app.getreview.io/tags/L3X9dU7GnLZarLX3/sdk.js')
    );
  }

  ngOnInit() {
    this.#router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.#activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        if (!!event['title']) this.#metaService.updateTitle(event['title']);
        if (!!event['description']) this.#metaService.updateDescription(event['description']);
      });
  }
}
