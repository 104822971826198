export const loadScript = (id: string, src: string): void => {
  if (typeof document !== 'undefined' && !document.getElementById(id)) {
    if (document.getElementById(id)) return;

    const signInJS = document.createElement('script');

    signInJS.async = true;
    signInJS.src = src;
    signInJS.id = id;

    document.head.appendChild(signInJS);
  }
};
