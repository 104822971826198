import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MgLogoComponent } from '@marketguru/shared';
import { AuthorizedButtonComponent } from '../authorized-button/authorized-button.component';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [MgLogoComponent, AuthorizedButtonComponent, RouterLink, NgOptimizedImage]
})
export class FooterComponent implements OnInit {
  currentYear = 2023;

  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
  }
}
