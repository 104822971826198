import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject, PLATFORM_ID, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MgSaleBannerComponent } from '@marketguru/shared';
import { CookieBannerComponent } from '../cookie-banner/cookie-banner.component';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { IntensiveTimerComponent } from '../intensive-timer/intensive-timer.component';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
  imports: [
    HeaderComponent,
    RouterOutlet,
    FooterComponent,
    CookieBannerComponent,
    IntensiveTimerComponent,
    MgSaleBannerComponent
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppLayoutComponent {
  readonly #router = inject(Router);

  isDocsPage = signal(false);

  protected readonly isBrowser = signal(false);

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser.set(isPlatformBrowser(platformId));

    this.#router.events.pipe(takeUntilDestroyed()).subscribe((events) => {
      if (events instanceof NavigationEnd) {
        this.isDocsPage.set(events.url.includes('/docs'));
      }
    });
  }
}
