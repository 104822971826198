import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  input,
  output
} from '@angular/core';
import { menuItems } from './menu-items';
import { RouterLink } from '@angular/router';
import { MgLogoComponent } from '@marketguru/shared';
import { MgButtonComponent } from '@marketguru/core';
import { wizardUrl } from '../../../utils/wizard-url';
import { UtmService } from '../../../services/utm.service';

@Component({
  selector: 'app-header-nav',
  standalone: true,
  imports: [RouterLink, MgLogoComponent, MgButtonComponent],
  templateUrl: './header-nav.component.html',
  styleUrl: './header-nav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderNavComponent {
  readonly menuItems = menuItems;
  isOpen = input(false);
  readonly mainHost = wizardUrl();
  protected readonly utmService = inject(UtmService);

  closeMenu = output();

  @HostBinding('class.open')
  get open() {
    return this.isOpen();
  }
}
