import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isAfter } from 'date-fns';
import { orderBy } from 'lodash-es';
import { debounceTime } from 'rxjs/operators';
import { IBanner, IBannerTimer } from '../../interfaces/banner';
import { getTimer } from '../../utils/get-timer';
import { IntensiveTimerService } from './intensive-timer.service';

@Component({
  selector: 'app-intensive-timer',
  templateUrl: './intensive-timer.component.html',
  styleUrls: ['./intensive-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule]
})
export class IntensiveTimerComponent implements OnInit, OnDestroy {
  @ViewChild('intensiveTimer') intensiveTimerElement?: ElementRef;

  intensive?: IBanner;

  timerInfo: IBannerTimer | null = {
    timerDays: '00',
    timerHours: '00',
    timerMinutes: '00'
  };

  timerInstants?: ReturnType<typeof setInterval>;

  isShowIntensiveTimer = false;

  constructor(
    @Inject(PLATFORM_ID) private _platformId: string,
    private cdr: ChangeDetectorRef,
    private intensiveTimerService: IntensiveTimerService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this._platformId))
      this.getIntensive();
  }

  ngOnDestroy(): void {
    if (this.timerInstants) clearInterval(this.timerInstants);
  }

  checkIntensive(): void {
    if (!!this.intensive) {
      this.cdr.markForCheck();

      this.isShowIntensiveTimer = true;
      this.timerInfo = getTimer(this.intensive.targetDate);
      this.cdr.detectChanges();

      this.timerInstants = setInterval(() => {
        if (!this.intensive) {
          this.onClose();
          return;
        }
        this.timerInfo = getTimer(this.intensive.targetDate);
        if (!this.timerInfo) clearInterval(this.timerInstants);

        this.cdr.detectChanges();
      }, 10000);

      setTimeout(() => {
        this.onClose();
        this.getIntensive();
      }, new Date(this.intensive.endDate).getTime() - new Date().getTime());
    }
    this.intensiveTimerService.timerHeight$.next(
      this.intensiveTimerElement?.nativeElement.offsetHeight || 0
    );
  }

  onClose(): void {
    this.isShowIntensiveTimer = false;
    clearInterval(this.timerInstants);
  }

  private getIntensive(): void {
    this.intensiveTimerService
      .getBanners()
      .pipe(debounceTime(50), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (banners) => {
          this.intensive = orderBy(
            banners.filter((banner) => {
              return (
                banner.type === 'intensive' &&
                banner.destination === 'public' &&
                isAfter(new Date(banner.endDate), new Date())
              );
            }),
            'order',
            'asc'
          )[0];
          if (this.intensive?.view?.bgBannerImage) {
            const image = new Image();
            image.onload = () => {
              this.checkIntensive();
            };
            image.src = this.intensive.view.bgBannerImage;
          } else {
            this.checkIntensive();
          }
        },
        error: () => {
          this.intensiveTimerService.timerHeight$.next(0);
        }
      });
  }
}
