<div
  #intensiveTimer
  *ngIf="!!intensive && isShowIntensiveTimer"
  [style.background-color]="intensive.view.bgBannerColor"
  [style]="intensive.view.bgBannerImage ? { 'background-image': 'url(' + intensive.view.bgBannerImage + ')' } : {}"
  class="intensive-timer"
>
  <div class="intensive-timer__content">
    <div [style.text-align]="intensive.view.textAlign" class="intensive-timer__content__info">
      <div
        *ngIf="intensive.title"
        [innerHTML]="intensive.title"
        [style.color]="intensive.view.colorTitle"
        class="intensive-timer__content__info__title"
      ></div>
      <div
        [innerHTML]="intensive.message"
        [style.color]="intensive.view.colorText"
        class="intensive-timer__content__info__message"
      ></div>
    </div>

    <div
      [style.border-color]="intensive.view.colorTimer"
      [style.color]="intensive.view.colorTimer"
      class="intensive-timer__content__timer-block"
    >
      <ng-container *ngIf="timerInfo; else timerText">
        <div class="intensive-timer__content__timer-block__elem">{{ timerInfo.timerDays }}д</div>
        <div class="intensive-timer__content__timer-block__dots">:</div>
        <div class="intensive-timer__content__timer-block__elem">{{ timerInfo.timerHours }}ч</div>
        <div class="intensive-timer__content__timer-block__dots">:</div>
        <div class="intensive-timer__content__timer-block__elem">{{ timerInfo.timerMinutes }}м</div>
      </ng-container>

      <ng-template #timerText>
        <div class="intensive-timer__content__timer-block__text">
          <div
            [style.background-color]="intensive.view.colorTimer"
            class="intensive-timer__content__timer-block__text__dot"
          ></div>
          <span>Событие началось</span>
        </div>
      </ng-template>
    </div>
    <a
      [class.default]="!intensive.view.bgColorBtn && !intensive.view.colorBtn"
      [href]="intensive.link"
      [style.background-color]="intensive.view.bgColorBtn"
      [style.color]="intensive.view.colorBtn"
      [style.font-size]="intensive.view.fontSizeBtn + 'px'"
      class="intensive-timer__btn"
      target="_blank"
    >
      {{ intensive.view.buttonText || 'Забронировать место' }}
    </a>
  </div>
</div>
