import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { wizardUrl } from '../utils/wizard-url';

export const apiPrefixInterceptor: HttpInterceptorFn = (request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  if (!request.url.startsWith('/') || /.(svg|json)$/.test(request.url)) {
    return next(request);
  }

  const apiUrl = wizardUrl();

  const apiReq = request.clone({ url: `${apiUrl}${request.url}` });
  return next(apiReq);
}