import { IBannerTimer } from '../interfaces/banner';

export const getTimer = (date: string): IBannerTimer | null => {
  const timerDistance = new Date(date).getTime() - new Date().getTime();
  const timerObj: IBannerTimer = {
    timerDays: '00',
    timerHours: '00',
    timerMinutes: '00'
  };

  if (timerDistance < 0) {
    return null;
  }

  const days = Math.floor(timerDistance / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timerDistance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timerDistance % (1000 * 60 * 60)) / (1000 * 60));

  timerObj.timerDays = days.toString().padStart(2, '0');
  timerObj.timerHours = hours.toString().padStart(2, '0');
  timerObj.timerMinutes = minutes.toString().padStart(2, '0');

  return timerObj;
};
